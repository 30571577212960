import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from '@app/history';
import routes from '@app/routes'

function App() {
  return (
    <div className="app">
        <Router history={history}>
            <Switch>
                {routes.map((route, i) => (
                    <Route
                        path={route.path}
                        key={route.path}
                        component={route.component}
                        exact
                    />
                ))}
            </Switch>
        </Router>
    </div>
  );
}

export default App;
