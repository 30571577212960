import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Box, IconButton, TextField, Button, Menu, MenuItem, CircularProgress, Fade} from "@material-ui/core";
import _ from "lodash";
import Hls from 'hls.js';
import moment from 'moment';
import SeekSlider from "./seek-slider";
// import ThldSlider from "./thld-slider";
import config from "../app/config";
import {hls, setHls} from "../app/hls";
import api from "../app/api";
import {isIOS, isAndroid, isMobile, isSafari, isTablet} from 'react-device-detect'
import usePrevious from "../hooks/use-previous";
import axios from "axios";
import {useStyles} from "./player.styles";

const ENABLE_HLS_JS_LOGS = false

const BREAKPOINTS_COUNT = 10

const HEADER_PURE_DURATION = 'X-PURE-DURATION'
const HEADER_TOTAL_DURATION = 'X-TOTAL-DURATION'
const HEADER_NEXT_TIME = 'X-NEXT-TIME'
//const HEADER_SEGMENTS_FILE = 'X-SEGMENTS-FILE'

const DEFAULT_COMPRESSION_OPTIONS = [
    { label: '1x', compression: 100, thld: 0 },
    { label: '1.25x', compression: 80, thld: 12 },
    { label: '1.5x', compression: 66.7, thld: 25 },
    { label: '2x', compression: 50, thld: 50 },
    { label: '2.5x', compression: 40, thld: 75 },
    { label: '3x', compression: 33.3, thld: 100 },
]

const isIPhone = isIOS && isMobile && !isTablet && isSafari

export default function Player(props) {

    const params = props.match.params
    const { preset_id } = params

    const urlParams = new URLSearchParams(props.location.search)
    const isDebugMode = !!urlParams.get('debug')
    const presetToken = urlParams.get('token')

    const [isDeprecated, setIsDeprecated] = useState(null)

    const [fadeControls, setFadeControls] = useState(false)
    const fadeTimeout = useRef(null)
    const hasFirstPlay = useRef(false) // When fading should be activated
    const isInitThldChanged = useRef(false) // When user changed thld at least once

    const playPauseRef = useRef(null)

    const keepAliveInterval = useRef(null)

    const sessionId = useRef(Math.random().toString(36).substring(2, 9))

    const classes = useStyles()

    const playerRef = useRef(null)
    const player = () => playerRef?.current || null

    const [paused, setPaused] = useState(true)

    const [seeking, setSeeking] = useState(false)
    const beforeLoadPaused = useRef(true)
    const loading = useRef(false)
    const [isLoading, setIsLoading] = useState(false)

    const [duration, setDuration] = useState(0)
    const [pureDuration, setPureDuration] = useState(0)
    const [totalDuration, setTotalDuration] = useState(0)
    const origDuration = useRef(null)
    const [seek, setSeek] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)

    const [tmpThld, setTmpThld] = useState(null)
    const [thld, setThld] = useState(0)
    const [minCompressionWithFF, setMinCompressionWithFF] = useState(0)
    const [maxThldWithFF, setMaxThldWithFF] = useState(100)
    const [breakpoints, setBreakpoints] = useState([])
    const nextTimeIOS = useRef(null)
    const iosSourceLoadedPromise = useRef(null)

    const [isSafariCanPlay, setIsSafariCanPlay] = useState(false)

    // More Views Dropdown
    const [preset, setPreset] = useState({});
    const [subPresets, setSubPresets] = useState([]);
    const [compressionOptions, setCompressionOptions] = useState(DEFAULT_COMPRESSION_OPTIONS)
    const [subPresetId, setSubPresetId] = useState(null);
    const [openPresetMenu, setOpenPresetMenu] = useState(false);
    const [openCompressionMenu, setOpenCompressionMenu] = useState(false);
    const compressionRef = useRef()
    const subPresetListRef = useRef()

    // For activity logs
    const device = useMemo(() => {
        return isIOS ? 'ios' : isAndroid ? 'android' : 'desktop'
    }, [isIOS, isAndroid])

    const prevThld = usePrevious(thld)

    const currentPresetId = useMemo(
        () => (subPresetId || preset_id),
        [ subPresetId, preset_id ]
    )

    // Track prev preset
    const prevPresetId = useRef(preset_id)
    const switchPreset = useRef(false)

    const onSubPresetIdChange = newSubPresetId => {
        prevPresetId.current = !subPresetId ? preset_id: subPresetId
        switchPreset.current = true
        setSubPresetId(newSubPresetId)
    }

    const getBreakpointsThld = () => {
        const step = Math.ceil(100/(BREAKPOINTS_COUNT-1))
        const bps = []
        for (let i = 0; i < BREAKPOINTS_COUNT - 1; i ++) {
            bps.push(i * Math.ceil(step))
        }
        bps.push(100)
        return bps
    }

    const matchedBreakpoints = useMemo(() => {
        if (!breakpoints.length) {
            return []
        }
        const currThld = tmpThld || thld
        for (let i = 0; i < BREAKPOINTS_COUNT - 1; i ++) {
            if (
                breakpoints[i].thld <= currThld
                && breakpoints[i+1].thld >= currThld
            ) {
                return [
                    breakpoints[i],
                    breakpoints[i+1]
                ]
            }
        }
        return []
    }, [breakpoints, breakpoints, tmpThld, thld])

    const buildSourceUrl = (extendParams = {}) => {
        const params = {
            thld: thld,
            preset_id: currentPresetId,
            prev_thld: prevThld || 0,
            prev_time: currentTime,
            session_id: sessionId.current,
            ...extendParams
        }
        // Inform api that we want to see FF, based on approx compression
        if (thld <= maxThldWithFF) {
            params.include_ff = true
        }
        // Attach prev preset id only in case it really changed
        if (switchPreset.current) {
            params.prev_preset_id = prevPresetId.current
            switchPreset.current = false
        }
        return `${config.api}hls/process?${new URLSearchParams(params).toString()}`
    }

    const sourceUrl = useMemo(() => {
        console.debug(`Thld Change ${prevThld} -> ${thld}`)
        return buildSourceUrl()
    }, [preset_id, subPresetId, thld])

    const getSourceUrlForStream = () => {
        let url = `${sourceUrl}&return_stream=1&device=${device}`
        if (compressionForecast) {
            url += `&approx_compression=${compressionForecast}`
        }
        return url
    }

    const calculateCompression = (duration) => {
        const tmpCompression = !origDuration.current
            ? null
            : !duration
                ? 0
                : Math.round(10 * (duration/origDuration.current) * 100) / 10
        //console.debug(`calculateCompression=${duration}/${origDuration.current}*100=${tmpCompression}`)
        return tmpCompression
    }

    const handleLoadSource = () => {
        if (loading.current) {
            ENABLE_HLS_JS_LOGS && console.debug('Skip loading source due to another pending load')
            return;
        }
        const url = getSourceUrlForStream()
        ENABLE_HLS_JS_LOGS && console.debug('Setting beforeLoadPaused', beforeLoadPaused.current)
        loading.current = true
        setIsLoading(true)
        ENABLE_HLS_JS_LOGS && console.debug('LoadSource - started')
        let newHls = hls

        if (newHls !== null) {
            newHls.destroy()
            newHls = null
            ENABLE_HLS_JS_LOGS && console.debug('LoadSource - destroyed old')
            setDuration(0)
        }
        newHls = new Hls({
            lowLatencyMode: true
        })
        ENABLE_HLS_JS_LOGS && console.debug('LoadSource - newHls = new Hls()', newHls)
        newHls.attachMedia(player())
        ENABLE_HLS_JS_LOGS && console.debug('LoadSource - newHls.attachMedia(player())', player())
        // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
        newHls.on(Hls.Events.MEDIA_ATTACHED, function () {
            ENABLE_HLS_JS_LOGS && console.debug('LoadSource - media attached')
            newHls.loadSource(url)
        })
        newHls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            const level = [...data.levels].shift()
            setDuration(level.details.totalduration)
            ENABLE_HLS_JS_LOGS && console.debug('LoadSource - manifest parsed', event, data)
        })
        newHls.on(Hls.Events.MANIFEST_PARSING_ERROR, function (event, data) {
            ENABLE_HLS_JS_LOGS && console.debug('LoadSource - manifest parsing error')
        })
        newHls.on(Hls.Events.MANIFEST_LOAD_TIMEOUT, function (event, data) {
            ENABLE_HLS_JS_LOGS && console.debug('LoadSource - manifest load timeout')
        })
        newHls.on(Hls.Events.MANIFEST_LOADED, function (event, data) {
            ENABLE_HLS_JS_LOGS && console.debug('LoadSource - manifest loaded')
            // GET HLS DURATION FROM HEADER
            const scopedPureDuration = parseFloat(
                data.networkDetails.getResponseHeader(HEADER_PURE_DURATION)
            )
            setPureDuration(scopedPureDuration)

            const scopedTotalDuration = parseFloat(
                data.networkDetails.getResponseHeader(HEADER_TOTAL_DURATION)
            )
            setTotalDuration(scopedTotalDuration)
            ENABLE_HLS_JS_LOGS && console.debug(`Setting pureDuration=${pureDuration}; totalDuration=${scopedTotalDuration}`)

            // GET NEW SEEK POSITION FROM HEADER
            const nextTime = parseInt(
                data.networkDetails.getResponseHeader(HEADER_NEXT_TIME)
            )
            console.debug(`(Hls.js) Next seek time taken from api: ${nextTime}`)
            if (nextTime) {
                //console.debug(`Setting new seek position from header: ${nextTime}`)
                player().currentTime = nextTime;
            } else {
                //console.debug(`Setting new seek position from current seek: ${seekToTime(seek)}`)
                player().currentTime = seekToTime(seek)
            }
            //console.debug('Checking beforeLoadPaused', beforeLoadPaused.current)
            // Play if needed
            if (!beforeLoadPaused.current) {
                try {
                    console.debug('Play the video based on previous state')
                    setTimeout(() => {
                        play(true)
                    }, 0)
                } catch(ex) {
                    console.debug('Cant resume playing after loading new source', ex)
                }
            }
            loading.current = false
            setIsLoading(false)
        })
        newHls.on(Hls.Events.MANIFEST_LOAD_ERROR, function (event, data) {
            loading.current = false
            setIsLoading(false)
            ENABLE_HLS_JS_LOGS && console.debug('LoadSource - manifest load error')
        })
        newHls.on(Hls.Events.MANIFEST_INCOMPATIBLE_CODECS_ERROR, function (event, data) {
            loading.current = false
            setIsLoading(false)
            ENABLE_HLS_JS_LOGS && console.debug('LoadSource - manifest incompatible codecs error')
        })
        setHls(newHls)
    }

    const handleLoadSourceIOS = async () => {
        try {
            iosSourceLoadedPromise.current = new Promise(async (resolve, reject) => {
                const response = (await axios.get(sourceUrl))
                // Track thld change
                const nextTime = parseInt(
                    response?.headers[HEADER_NEXT_TIME.toLowerCase()]
                )
                if(nextTime) {
                    nextTimeIOS.current = nextTime
                } else {
                    nextTimeIOS.current = seekToTime(seek)
                }
                resolve(nextTimeIOS.current)
            })
        } catch (err) {
            console.debug('handleLoadSourceIOS failed', sourceUrl, err)
        }
    }

    const loadSource = useCallback(
        handleLoadSource,
        [sourceUrl]
    )

    const compression = useMemo(() => {
        if (origDuration.current) {
            return calculateCompression(pureDuration)
        } else {
            return null
        }
    },[pureDuration, origDuration.current])

    const compressionForecast = useMemo(() => {
        const [breakpointX, breakpointY] = matchedBreakpoints
        if (!breakpointX || !breakpointY) {
            return null
        }
        const thldDiffInRange = breakpointY.thld - breakpointX.thld
        const compressionDiffInRange = breakpointX.compression - breakpointY.compression
        const progressInRange = ((tmpThld || thld) - breakpointX.thld) / thldDiffInRange
        const value = breakpointX.compression - compressionDiffInRange * progressInRange
        return Math.round(
            10 * value
        ) / 10
    }, [tmpThld, thld, matchedBreakpoints])

    const durationForecast = useMemo(() => {
        const [breakpointX, breakpointY] = matchedBreakpoints
        if (!breakpointX || !breakpointY) {
            return null
        }
        const thldDiffInRange = breakpointY.thld - breakpointX.thld
        const durationDiffInRange = breakpointX.duration - breakpointY.duration
        const progressInRange = ((tmpThld || thld) - breakpointX.thld) / thldDiffInRange
        const value = breakpointX.duration - durationDiffInRange * progressInRange
        return value
    }, [tmpThld, thld, matchedBreakpoints])

    const remainingTimeForecast = useMemo(() => {
        //console.debug(`Duration forecast=${durationForecast}`)
        let remaining = durationForecast - currentTime
        remaining = Math.max(0, remaining)
        return moment.utc(remaining * 1000).format('HH:mm:ss')
    }, [durationForecast, currentTime])

    useEffect(() => {
        console.log(`isMobile=${isMobile}`)
        console.log(`isTablet=${isTablet}`)
        console.log(`isIOS=${isIOS}`)
        console.log(`isSafari=${isSafari}`)
        console.log(`isIPhone=${isIPhone}`)
    }, [])

    useEffect(() => {
        // Linear seek recalculation replaced with value from api header
        // if(duration) {
        //     player().currentTime = seek / 1000 * duration
        //     // Play if needed
        //     if(!beforeLoadPaused) {
        //         play()
        //     }
        //     loading = false
        //     console.debug('Duration changed: Recalculated')
        // }
    }, [duration])// eslint-disable-line

    const onOpenPresetMenu = () => {
        if (!fadeControls) {
            setOpenPresetMenu(true)
        }
    }

    const onClosePresetMenu = () => {
        setOpenPresetMenu(false)
    }
    
    const onOpenCompressionMenu = () => {
        setOpenCompressionMenu(true)
    }

    const onCloseCompressionMenu = () => {
        setOpenCompressionMenu(false)
    }

    const onSelectSubPreset = (subPresetId) => {
        // setSeek(0)
        // setCurrentTime(0);
        // player().currentTime = 0;
        // player().pause();
        // setPaused(true);

        setOpenPresetMenu(false)
        onSubPresetIdChange(subPresetId)
    }
    
    const onSelectedCompression = (val) => {
        setPaused(true)
        setThld(val)
    }

    useEffect(() => {
        if (isDeprecated === false) {
            keepAliveInterval.current = setInterval(() => {
                api.pingKeepAlive({
                    sessionId: sessionId.current,
                    playing: !player().paused,
                    totalDuration
                })
            }, 5000)
        }
        return () => {
            if (keepAliveInterval.current) {
                clearInterval(
                    keepAliveInterval.current
                )
            }
        }
    }, [ isDeprecated, totalDuration ])

    useEffect(() => {
        api.getStreamInfo(preset_id).then( ({ data }) => {
            if (data.token && data.token !== presetToken) {
                setIsDeprecated(true)
            } else {
                setPreset(data)
                setIsDeprecated(false)
            }
        });
        api.getSubPresets(preset_id).then( ({ data }) => {
            setSubPresets(data);
        });
    }, [pureDuration])

    useEffect(() => {
        if (breakpoints.length && minCompressionWithFF !== 0) {
            setupMaxThldWithFF()
        }
    }, [minCompressionWithFF, breakpoints])

    useEffect(() => {
        if (breakpoints.length) {
            setupCompressionOptions()
        }
    }, [breakpoints])

    const awaitFade = () => {
        if (fadeTimeout.current) {
            clearTimeout(fadeTimeout.current)
        }
        if (!!hasFirstPlay.current) {
            fadeTimeout.current = setTimeout(() => {
                setFadeControls(true)
                setOpenPresetMenu(false)
                setOpenCompressionMenu(false)
            }, 2000)
        }
    }

    useEffect(() => {
        api.getStreamInfo(currentPresetId).then( ({ data }) => {
            origDuration.current = data.stream_duration
            setMinCompressionWithFF(data.min_compression_with_ff || 0)
            // Fetch breakpoints data
            const breakpointsThld = getBreakpointsThld()
            let breakpointsData = []
            Promise.all(
                breakpointsThld.map(bpThld =>
                    api.getStream({
                        preset_id: currentPresetId,
                        thld: bpThld,
                        include_ff: bpThld <= maxThldWithFF
                    }).then( ({headers}) => {
                        const bpPureDuration = parseFloat(headers[HEADER_PURE_DURATION.toLowerCase()] || 0)
                        breakpointsData.push({
                            thld: bpThld,
                            pureDuration: bpPureDuration,
                            duration: parseFloat(headers[HEADER_TOTAL_DURATION.toLowerCase()] || 0),
                            compression: calculateCompression(bpPureDuration)
                        })
                    })
                )
            ).then(() => {
                breakpointsData = _.sortBy(breakpointsData, 'thld')
                setBreakpoints(breakpointsData)
            })
        })

        // Hide controls in few sec inactivity
        document.addEventListener('mousemove', (e) => {
            if (isIPhone) return
            awaitFade()
        })

        // Listen loaded event for thld change on ios
        if (isSafari) {
            player().addEventListener('durationchange', () => {
                const newDuration = player().duration
                console.debug(`Loading new video source, new duration=${newDuration}`)
                setDuration(newDuration)
                setPureDuration(newDuration)
            })
            // IPhone
            if (isIPhone) {
                player().addEventListener('loadeddata', () => {
                    if ( typeof iosSourceLoadedPromise === 'object') {
                        iosSourceLoadedPromise.current.then( () => {
                            if (nextTimeIOS.current) {
                                console.debug(`Applying calculated seek: ${nextTimeIOS.current}, is paused before: ${beforeLoadPaused.current}`)
                                player().currentTime = nextTimeIOS.current
                                nextTimeIOS.current = null
                            }
                        })
                    }
                })
            } else {
                player().addEventListener('waiting', () => {
                    setIsSafariCanPlay(false)
                    console.debug('Safari waiting...')
                })
                player().addEventListener('canplay', () => {
                    setIsSafariCanPlay(true)
                    console.debug(`Safari CanPlay!`)
                    if ( iosSourceLoadedPromise.current) {
                        iosSourceLoadedPromise.current.then( () => {
                            iosSourceLoadedPromise.current = null
                            if (nextTimeIOS.current) {
                                console.debug(`Applying calculated seek: ${nextTimeIOS.current}, is paused before: ${beforeLoadPaused.current}`)
                                player().currentTime = nextTimeIOS.current
                                nextTimeIOS.current = null
                                // If video playing when threshold changed and its not mobile ios
                                if (!beforeLoadPaused.current) {
                                    try {
                                        console.log('Try play...')
                                        setTimeout(() => {
                                            play(true)
                                        }, 100)
                                    } catch(ex) {
                                        console.debug('Cant resume playing after loading new source', ex)
                                    }
                                }
                            }
                        })
                    }
                })
            }
            player().addEventListener('pause', () => {
                // Show controls for IOS on pause
                setFadeControls(false)
            })
        }
    }, [subPresetId])// eslint-disable-line

    useEffect(() => {
        if (isDeprecated === false) {
            if (!isSafari) {
                loadSource()
            } else {
                player().load()
                // Send same source stream request to get metadata like next seek position
                handleLoadSourceIOS()
            }
        }
    }, [sourceUrl, isDeprecated]) // eslint-disable-line

    const setupMaxThldWithFF = () => {
        let breakpointX = null
        let breakpointY = null
        for (let i = 0; i < BREAKPOINTS_COUNT - 1; i ++) {
            if (
                breakpoints[i].compression >= minCompressionWithFF
                && breakpoints[i+1].compression <= minCompressionWithFF
            ) {
                breakpointX = breakpoints[i]
                breakpointY = breakpoints[i+1]
            }
        }
        if (breakpointX && breakpointY) {
            const maxThldWithFFCalc = breakpointX.thld
                + (breakpointY.thld - breakpointX.thld)
                * (
                    (breakpointX.compression - minCompressionWithFF)
                    / (breakpointX.compression - breakpointY.compression)
                )
            setMaxThldWithFF(maxThldWithFFCalc)
        }
    }

    const setupCompressionOptions = () => {
        // { label: '1x', desc: '100%', value: 0 }
        const options = DEFAULT_COMPRESSION_OPTIONS.map( (option, iOption) => {
            if (iOption === 0) {
                return option
            }
            let calcThld = null
            let breakpointX = null
            let breakpointY = null
            for (let i = 0; i < BREAKPOINTS_COUNT - 1; i ++) {
                if (
                    breakpoints[i].compression >= option.compression
                    && breakpoints[i+1].compression <= option.compression
                ) {
                    breakpointX = breakpoints[i]
                    breakpointY = breakpoints[i+1]
                }
            }
            if (breakpointX && breakpointY) {
                calcThld = breakpointX.thld
                    + (breakpointY.thld - breakpointX.thld)
                    * (
                        (breakpointX.compression - option.compression)
                        / (breakpointX.compression - breakpointY.compression)
                    )
            }
            return {
                ...option,
                thld: calcThld !== null
                    ? Math.round(calcThld)
                    : 100
            }
        })
        setCompressionOptions(options)
        console.debug('Compression options | breakpoints', options, breakpoints)
    }

    const play = (fadeControlsWithDelay = false) => {
        if (player().paused) {
            player().play()
            setPaused(false)
            // Mark that first play done to activate fading controls
            if (!hasFirstPlay.current) {
                hasFirstPlay.current = true
            }
            // Hide the controls
            if (isIPhone) return
            if (fadeControlsWithDelay) {
                awaitFade()
            } else {
                setFadeControls(true)
                setOpenPresetMenu(false)
                setOpenCompressionMenu(false)
            }
        }

    }

    const pause = () => {
        if(!player().paused) {
            player().pause()
        }
        setPaused(true)
    }

    const playPause = () => {
        if (!loading.current && !fadeControls && (!isSafari || isIPhone || isSafariCanPlay)) {
            if (paused) {
                play()
            } else {
                pause()
            }
        }
    }

    const goForward = () => {
        if (!fadeControls) {
            player().currentTime += 30
        }
    }

    const goBack = () => {
        if (!fadeControls) {
            player().currentTime -= 15;
        }
    }

    const seekToTime = (seek) => {
        if (!seek) {
            return 0
        }
        return (seek / 1000) * duration
    }

    const timeToSeek = (seconds) => {
        return (seconds / duration) * 1000
    }

    const onSeekChange = (event, value) => {
        if (!loading.current  && !fadeControls && (!isSafari || isIPhone || isSafariCanPlay)){
            setSeeking(true)
            setSeek(value)
        }
    }

    const onSeekChangeCommitted = (event, value) => {
        if(!loading.current && !fadeControls && (!isSafari || isIPhone || isSafariCanPlay)) {
            setSeek(value)
            player().currentTime = Math.round(seekToTime(value))
            setSeeking(false)
            console.debug(`Seek applied: ${value} as time ${seekToTime(value)}`)
        }
    }

    // const resetDuration = () => {
    //     if(thld === 100) {
    //         setDuration(0)
    //         setPureDuration(0)
    //     }
    // }

    const onThldChange = (value) => {
        if (fadeControls) {
            return
        }
        setTmpThld(value)
        awaitFade()
        //console.debug('thld:onThldChange', value)
    }

    const onThldChangeCommitted = (value) => {
        if (fadeControls) {
            return
        }
        console.debug(`Saving if before load paused as ${beforeLoadPaused.current}`)
        beforeLoadPaused.current = !!paused
        isInitThldChanged.current = true
        setTmpThld(null)
        setThld(value)
        setIsSafariCanPlay(false)
        console.debug('thld:onThldChangeCommitted', value)
        // setThldSeeking(true)
        // if (value === 0) {
        //     resetDuration()
        // }
    }

    // const onThldChangeCommitted = _.throttle((event, value) => {
    //     setThld(value);
    //     setTimeout(() => {
    //         setThldSeeking(false)
    //     }, 0)
    //     if(thld === 100) {
    //         resetDuration()
    //     }
    //     console.debug('onThldChangeCommitted')
    // }, 200)

    const remainingTime = useMemo(() => {
        let seconds = (duration - currentTime)
        seconds = Math.max(0, seconds)
        return moment.utc(seconds * 1000).format('HH:mm:ss')
    }, [currentTime, duration])

    const remainingTimeRender = useMemo(() => {
        //console.debug('remainingTimeRender, loading=', isLoading, ';remainingTime=', remainingTime, ';remainingTimeForecast=', remainingTimeForecast)
        return !isLoading && !tmpThld
            ? remainingTime
            : remainingTimeForecast
    }, [isLoading, tmpThld, remainingTime, remainingTimeForecast])

    const compressionRender = useMemo(() => {
        // Trying to pick compression option label by thld
        const compressionOptionLabel = compressionOptions.find(opt => opt.thld === thld)?.label
        if (compressionOptionLabel) return compressionOptionLabel

        // Manual calculation as 1.5x etc
        let compressionValue = 100
        let desc = ''
        if ((tmpThld || thld) === 0) {
            compressionValue = 100
            desc = 'default'
        } else {
            compressionValue = !isLoading && !tmpThld
                ? compression
                : compressionForecast
            desc = !isLoading && !tmpThld? 'api loaded': 'forecast'
        }
        //console.debug(`compressionRender (${desc}): loading=${isLoading}; compression=${compression}; compressionForecast=${compressionForecast}; tmpThld=${tmpThld};`)
        if (!compressionValue) {
            compressionValue = 100
        }
        return `${Math.round((100 / compressionValue) * 10) / 10}x`
    }, [isLoading, tmpThld, thld, compression, compressionForecast])

    const onVideoTimeUpdate = () => {
        if (loading.current) {
            return;
        }
        //console.debug(`${player().currentTime} / ${duration} = ${duration? (player().currentTime/duration*100): 0}%`)
        const currentTime = player().currentTime;
        setCurrentTime(currentTime);

        if (!seeking) {
            setSeek(
                timeToSeek(currentTime)
            );
        }
    }

    if (isDeprecated) {
        return (
            <Box
                className={classes.root}
                style={{
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Box style={{color: '#fff', fontSize: '1.5rem'}}>
                    <i>This video no longer available...</i>
                </Box>
            </Box>
        )
    }

    return (
        <Box
            className={classes.root}
            onClick={() => {
                if (!fadeControls) {
                    setFadeControls(true)
                    setOpenPresetMenu(false)
                    setOpenCompressionMenu(false)
                }
            }}
        >
            {/* PLAYER AND OVERLAY */}
            <video
                ref={playerRef}
                className={classes.video}
                onTimeUpdate={onVideoTimeUpdate}
                onPause={pause}
                onPlay={play}
                id={'player'}
            >
                <source src={getSourceUrlForStream()} type="application/x-mpegURL"/>
            </video>
            <Box className={classes.blueOverlay}/>
            {/* START / STOP */}
            <Box
                className={`${classes.controlsLayer} ${!!fadeControls? classes.fade: ''}`}
                style={{
                    zIndex: !fadeControls? 10: 9
                }}
            >
                { window.history.length > 1 && (
                    <Box className={classes.backButtonWrapper}>
                        <Box className={classes.backButton}>
                            <IconButton
                                className={classes.backButtonIcon}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    window.history.back()
                                    console.debug(window.history.length)
                                }}
                            >
                                <img height={'30px'} src={'/img/btn_navigate_back.png'}/>
                            </IconButton>
                            <Box display={'flex'} className={classes.backButtonText}>
                                BACK
                            </Box>
                        </Box>

                    </Box>
                )}
                <Box className={classes.controlsWrapper}>
                    <Box className={classes.controlsSubWrapper}>
                        <Box className={classes.timeRemaining}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Box display={'flex'}>
                                    time remaining
                                </Box>
                                <Box display={'flex'}>
                                    {remainingTimeRender}
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.controls}>
                            <Box display={'flex'}>
                                <IconButton
                                    className={'control-btn'}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        goBack()
                                    }}
                                >
                                    <img alt={'back'} src={'/img/btn_back_15.png'}/>
                                </IconButton>
                            </Box>
                            <Box display={'flex'}>
                                <IconButton
                                    className={'control-btn'}
                                    ref={playPauseRef}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        playPause()
                                    }}
                                >
                                    { paused
                                        ? <img alt={'back'} src={'/img/btn_play.png'}/>
                                        : <img alt={'back'} src={'/img/btn_pause.png'}/>
                                    }
                                </IconButton>
                            </Box>
                            <Box display={'flex'}>
                                <IconButton
                                    className={'control-btn'}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                        goForward()
                                    }}
                                >
                                    <img alt={'forward'} src={'/img/btn_forward.png'}/>
                                </IconButton>
                            </Box>
                        </Box>

                        {/*<Box*/}
                        {/*    className={classes.thldSlider}*/}
                        {/*    onClick={(event) => {*/}
                        {/*        event.stopPropagation()*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <ThldSlider*/}
                        {/*        value={thld}*/}
                        {/*        onChange={onThldChange}*/}
                        {/*        onChangeCommitted={onThldChangeCommitted}*/}
                        {/*    />*/}
                        {/*</Box>*/}

                        {/* INFO AND SUB PRESETS LIST */}
                        <Box className={classes.infoWrapper}>
                            <Box className={classes.compressionWrapper}>
                                <Box
                                    ref={compressionRef}
                                    className={classes.compressionValue}
                                    onClick={event => {
                                        event.stopPropagation()
                                        onOpenCompressionMenu()
                                    }}
                                >
                                    { compressionRender }
                                </Box>
                                <Menu
                                    className={classes.menuCompression}
                                    open={openCompressionMenu}
                                    onClose={event => {
                                        event.stopPropagation()
                                        onCloseCompressionMenu()
                                    }}
                                    TransitionComponent={Fade}
                                    anchorEl={compressionRef.current}
                                    transformOrigin={{ vertical: -22, horizontal: 'center' }}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                >
                                    {compressionOptions.map((option) => (
                                        <MenuItem 
                                            key={`compression-item-${option.label}`}
                                            onClick={event => {
                                                event.stopPropagation()
                                                if (option.thld !== null) {
                                                    // Mark that we gonna play it after new thld loaded
                                                    beforeLoadPaused.current = false
                                                    // IOS - hide controls immediately cuz no autoplay
                                                    if (isIOS) {
                                                        onCloseCompressionMenu()
                                                    }
                                                    onSelectedCompression(option.thld)
                                                }
                                            }}
                                            selected={thld === option.thld}
                                            disabled={option.thld === null}
                                        >
                                            <Box
                                                display={'flex'}
                                                flexDirection={'column'}
                                                alignItems={'center'}
                                            >
                                                <Box display={'flex'}>
                                                    {option.label}
                                                </Box>
                                                <Box display={'flex'}>
                                                    {option.compression}%
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                            {subPresets.length > 0 && (
                                <Box
                                    className={classes.presetWrapper}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                    }}
                                >
                                    <Button
                                        className={classes.moreViewsButton}
                                        onClick={onOpenPresetMenu}
                                        ref={subPresetListRef}
                                    >
                                        MORE VIEWS
                                    </Button>
                                    <Menu
                                        className={classes.menuPreset}
                                        open={openPresetMenu}
                                        onClose={onClosePresetMenu}
                                        anchorEl={compressionRef.current}
                                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    >
                                        <MenuItem onClick={event => {
                                            event.stopPropagation()
                                            onSelectSubPreset(preset._id)
                                        }}>
                                            {preset.name}
                                        </MenuItem>
                                        {subPresets.map((subPreset, iSubPreset) => (
                                            <MenuItem key={iSubPreset} onClick={() => onSelectSubPreset(subPreset._id)}>
                                                {subPreset.name}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            )}
                        </Box>

                        {isDebugMode &&
                            <Box>
                                <TextField
                                    label="Thld"
                                    type="number"
                                    variant={'outlined'}
                                    value={thld}
                                    onChange={event => {
                                        setThld(event.target.value)
                                    }}
                                    inputProps={{
                                        min: 0,
                                        max: 100
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                        }
                    </Box>
                </Box>
                {/* SEEK */}
                <Box className={classes.seekSliderWrapper}>
                    <Box className={classes.seekSlider}>
                        <SeekSlider
                            min={0}
                            max={1000}
                            value={seek}
                            onChange={onSeekChange}
                            onChangeCommitted={onSeekChangeCommitted}
                        />
                    </Box>
                </Box>
            </Box>
            { isSafari && !isIPhone && !isSafariCanPlay &&
                <Box className={classes.loader}>
                    <CircularProgress size={70} />
                </Box>
            }
            <Box
                className={classes.clickOverlay}
                style={{
                    zIndex: fadeControls? 10: 9
                }}
                onClick={() => {
                    if (isIPhone) return
                    if (fadeControls) {
                        setFadeControls(false)
                        awaitFade()
                    } else {
                        setFadeControls(true)
                        setOpenPresetMenu(false)
                        setOpenCompressionMenu(false)
                    }
                }}
            />
        </Box>
    )
}
