import {withStyles} from "@material-ui/core/styles";
import {Slider} from "@material-ui/core";

const SeekSlider = withStyles({
    root: {
        color: '#FFF',
        opacity: 0.5,
        height: 10,
        width: '100%'
    },
    thumb: {
        height: 26,
        width: 26,
        backgroundColor: '#8cd3ff',
        opacity: 0.8,
        marginTop: -10,
        marginLeft: -10,
        borderRadius: 6,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 5,
        borderRadius: 4,
    },
    rail: {
        height: 5,
        borderRadius: 4,
        backgroundColor: '#FFF',
        opacity: 1
    },
})(Slider);

export default SeekSlider;