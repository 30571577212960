import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        position: 'relative',
        fontFamily: 'sans-serif',
    },
    // blueOverlay: {
    //     position: 'absolute',
    //     bottom: 0,
    //     height: '50%',
    //     width: '100%',
    //     background: 'linear-gradient(rgba(111, 208, 219, 0), rgba(111, 208, 219, 1))'
    // },
    video: {
        display: 'flex',
        height: '100%',
        width: '100%'
    },
    // PLAY / PAUSE
    backButtonWrapper: {
        position: 'fixed',
        display: 'flex',
        width: '100%',
        zIndex: 99,
        top: '20%',
        [theme.breakpoints.down('sm')]: {
            top: '5%',
        }
    },
    backButton: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'calc(15% - 86px)',
    },
    backButtonIcon: {
        //opacity: 0.5,
        '&:hover': {
            opacity: .8
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '5%',
        }
    },
    backButtonText: {
        color: '#fff',
        fontSize: '26px',
        padding: '0 12px'
    },
    controlsWrapper: {
        position: 'fixed',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99,
        top: '35%',
        [theme.breakpoints.down('sm')]: {
            top: '20%',
        }
    },
    controlsSubWrapper: {
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
    },
    timeRemaining: {
        display: 'flex',
        marginBottom: '2rem',
        justifyContent: 'center',
        color: '#fff',
        fontSize: '18px',
        //opacity: .5
    },
    // THLD SLIDER
    thldSlider: {
        display: 'flex',
        marginBottom: '1rem',
        position: 'relative',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        }
    },

    // CONTROLS
    controls: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '& .MuiIconButton-root': {
            transition: '0.5s all',
            //opacity: 0.5,
            '&:hover': {
                opacity: .8
            },
            '& img': {
                width: '30px'
            }
        },
        [theme.breakpoints.up('sm')]: {
            width: '50%'
        }
    },
    // SEEK
    seekSliderWrapper: {
        display: 'flex',
        position: 'fixed',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 99,
        bottom: '1.25rem'
    },
    seekSlider: {
        display: 'flex',
        marginTop: '.5rem',
        width: '90%'
    },
    // INFO
    infoWrapper: {
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
        marginTop: '2rem',
        width: '100%',
        //opacity: 0.8,
        [theme.breakpoints.down('sm')]: {
            marginTop: '1rem',
        }
    },
    compressionWrapper: {
        display: 'flex',
        position: "relative"
    },
    compressionValue: {
        display: 'flex',
        fontSize: '22px',
        fontWeight: '700',
        padding: '6px',
        borderRadius: '0.5rem',
        color: '#000',
        background: '#fff',
        //opacity: .5,
        transition: '0.5s all',
        cursor: 'pointer',
        '&:hover': {
            opacity: .8
        },
    },
    menuCompression: {
        display: 'flex',
        flexDirection: 'row',
        '& .MuiPaper-root': {
            borderRadius: '6px',
        },
        '& .MuiList-root': {
            display: 'flex',
            background: '#c1c1c1',
            padding: '10px',
            '& .MuiListItem-root': {
                fontWeight: 700,
                transition: '0.5s all',
                borderRadius: '6px',
                '&.Mui-selected': {
                    background: '#222',
                    color: '#ddd'
                },
                '&:hover': {
                    color: '#000',
                    background: '#ddd'
                }
            }
        }
    },
    presetWrapper: {
        display: 'flex',
        marginTop: '1rem'
    },
    menuPreset: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiList-root': {
            background: '#c1c1c1',
            '& .MuiListItem-root': {
                fontWeight: 700,
                transition: '0.5s all',
                '&:hover': {
                    color: '#000',
                    background: '#ddd'
                }
            }
        }
    },
    moreViewsButton: {
        color: '#000',
        background: '#fff',
        fontSize: '16px',
        lineHeight: '16px',
        fontWeight: '700',
        padding: '6px',
        borderRadius: '0.5rem',
        //opacity: .5,
        transition: '0.5s all',
        '&:hover': {
            opacity: .8,
            color: '#000',
            background: '#fff',
        },
    },
    controlsLayer: {
        top: 0,
        left: 0,
        position: 'absolute',
        zIndex: 99,
        width: '100vw',
        height: '100vh'
    },
    fade: {
        opacity: 0,
        transition: 'all 1.5s ease-in-out'
    },
    clickOverlay: {
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100vh',
        width: '100vw'
    },
    loader: {
        position: 'absolute',
        top: 'calc(50% - 35px)',
        left: 'calc(50% - 35px)',
        '& .MuiCircularProgress-svg': {
            color: '#FFF',
            //opacity: .6
        }
    }
}))