const visibilityFilter = (state = {

}, action) => {
    switch (action.type) {
        default: {
            return state;
        }
    }
};

export default visibilityFilter;
