import axios from './axios'

const api = {
    getStream: (config = {}) => {
        const {
            preset_id,
            thld = 0,
            ff_thld = 6,
            prev_thld = null,
            prev_time = null,
            include_ff = false
        } = config
        let url = `hls/process?thld=${thld}&ff_thld=${ff_thld}&preset_id=${preset_id}`
        if (prev_thld) {
            url += `&prev_thld=${prev_thld}`
        }
        if (prev_time) {
            url += `&prev_time=${prev_time}`
        }
        if (include_ff) {
            url += `&include_ff=true`
        }
        return axios.get(url)
    },
    getStreamInfo: (preset_id) => {
        return axios.get(`videos/presets/${preset_id}`)
    },
    getSubPresets: (preset_id) => {
        return axios.get(`videos/subpresets/${preset_id}`)
    },
    pingKeepAlive: ({sessionId, playing, totalDuration}) => {
        return axios.get(`logs/keep-session-alive?session_id=${sessionId}&playing=${playing? 1: 0}&total_duration=${totalDuration}`)
    },
}

export default api
