import axios from 'axios';
import config from './config';

const instance = axios.create({
    baseURL: config.api
});

instance.interceptors.request.use(

);

instance.interceptors.response.use(

);

export default instance;